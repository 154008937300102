<template>
  <svg
    class="v-ranged-present"
    fill="none"
    height="38"
    viewBox="0 0 42 38"
    width="42"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.33301 18H3.33301V19V32.3333C3.33301 33.4826 3.78955 34.5848 4.60221 35.3975C5.41487 36.2101 6.51707 36.6667 7.66634 36.6667H34.333C35.4823 36.6667 36.5845 36.2101 37.3971 35.3975C38.2098 34.5848 38.6663 33.4826 38.6663 32.3333V19V18H37.6663H22.6663H21.6663V19V31.3333H20.333V19V18H19.333H4.33301Z"
      fill="#DD6430"
      stroke="white"
      stroke-width="2"
    />
    <defs>
      <clipPath id="clip0_215_194">
        <rect
          fill="white"
          height="20"
          width="42"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@use 'assets/variables';

.v-ranged-present {
  fill: variables.$PrimaryBackgroundColor;

  path,
  circle {
    fill: variables.$PrimaryBackgroundColor;
    stroke: variables.$Background;
  }
}
</style>
